import Player from '@/player/index'

/**
 * SDK cdn 地址
 * <script src="player.polyv.net/script/player.js"></script>
 * 播放器文档地址： https://help.polyv.net/index.html#/vod/js/video_player/integration
 */
class Polyv {
  video = null
  event = {}
  playStatus = false
  constructor (param = {}, time = 3000) {
    this.video = window.polyvPlayer(param)
    const video = this.video
    // 模拟事件触发
    this.timeupdateTimer = setInterval(() => {
      const timeupdate = this.event.timeupdate
      if (timeupdate && timeupdate.length) {
        // if (this.playStatus) {
        const realPlayVideoTime = video.j2s_realPlayVideoTime()
        const time = video.j2s_getCurrentTime()
        const duration = video.j2s_getDuration()
        timeupdate.forEach(fn => {
          fn && fn(time, duration)
        })
        // }
      }
    }, time)
    this.video.on('s2j_onPlayOver', () => {
      clearInterval(this.timeupdateTimer)
    })
  }

  // 暂停播放
  pause () {
    this.video.j2s_pauseVideo()
  }

  // 播放视频 或者 恢复播放
  play () {
    this.video.j2s_resumeVideo()
  }

  // 快进
  seek (s = 0) {
    this.video.j2s_seekVideo(s)
  }

  // 销毁播放器
  destroy () {
    clearInterval(this.timeupdateTimer)
    this.video.destroy()
  }
  
  // 获取当前视频已播放的时长
  getRealPlayVideoTime () {
    return this.video.j2s_realPlayVideoTime()
  }

  // 获取当前播放时间点
  getCurrentTime () {
    return this.video.j2s_getCurrentTime()
  }

  // 获取总时长
  getDuration () {
    return this.video.j2s_getDuration()
  }

  // 获取当前播放时间点 / 总时长的百分比
  getPercentage () {
    // const realPlayVideoTime = this.getRealPlayVideoTime()
    // const time = this.getCurrentTime()
    // const d = this.getDuration()
    // if ((d - realPlayVideoTime) < 5) {
    //   return 100
    // }
    // return parseFloat((realPlayVideoTime / d * 100).toFixed(2))
    const time = this.getCurrentTime()
    const d = this.getDuration()
    if ((d - time) < 5) {
      return 100
    }
    return parseFloat((time / d * 100).toFixed(2))
  }

  // 获取播放器对象 用来实现没有实现统一api的调用
  getPlayer () {
    return this.video
  }

  getEventType (key) {
    const eventTypes = {
      ready: 's2j_onPlayerInitOver',
      play: 's2j_onVideoPlay',
      pause: 's2j_onVideoPause',
      seeked: 's2j_onVideoSeek',
      ended: 's2j_onPlayOver',
      volume_change: 's2j_volumeChange',
      error: 'serverError',
      timeupdate: 'timeupdate',
      full_screen_change: 's2j_onFullScreen,'
    }
    return eventTypes[key] || key
  }

  // 监听函数
  // 这个只是统一监听事件名 事件回调的参数没有做统一，各平台不一样 需要自行判断好
  on (key = '', fn = () => {}) {
    console.log(key)

    const type = this.getEventType(key)
    // 进度更新流程
    if (type === 'timeupdate') {
      const callbacks = this.event[type] || []
      callbacks.push(fn)
      this.event[type] = callbacks
      return this
    }

    // 全屏事件切换
    if (type === 'full_screen_change') {
      this.video.on('s2j_onFullScreen', fn)
      this.video.on('s2j_onNormalScreen', fn)
      return this
    }

    console.log(type)
    // 其他事件
    if (type) {
      this.video.on(type, fn)
    }
    return this
  }

  off (key = '', fn = () => {}) {
    const type = this.getEventType(key)
    // 进度更新流程
    if (type === 'timeupdate') {
      const callbacks = this.event[type]
      this.event[type] = callbacks && callbacks.filter(callback => callback !== fn)
      return this
    }
    // 全屏事件切换
    if (type === 'full_screen_change') {
      this.video.off('s2j_onFullScreen', fn)
      this.video.off('s2j_onNormalScreen', fn)
      return this
    }
    // 其他事件
    if (type) {
      this.video.off(type, fn)
    }
    return this
  }
}

export default Polyv
