/*
* type 点播平台类型 (2：保利威、3：百家云、4：欢拓云、10：私有云)
*
* time 上传进度的时间 默认是3秒
*
* 统一的方法
* const fnType = {
*   pause: 暂停播放
*   play: 播放视频 或者 恢复播放
*   seek: 快进(参数单位(秒))
*   destroy: 销毁播放器
*   getCurrentTime: 获取当前播放时间点
*   getDuration: 获取总时长
*   getPercentage: 获取当前播放时间点 / 总时长的百分比
*   getPlayer: 获取播放器对象 用来实现没有实现统一api的调用
* }
*
* 统一事件对象
* const eventTypes = {
*   ready: 播放器初始化完成
*   play: 首次播放
*   playing: 暂停后播放
*   pause: 暂停
*   seeked: 快进快退结束
*   ended: 播放结束
*   volume_change: 音量变化
*   rate_change: 倍速变化
*   timeupdate: 播放进度更新
*   quality_change: 切换清晰度
*   full_screen_change: 全屏/退出全屏
*   error: 错误事件监听
* }
*
* */
import Polyv from './polyv'
import Baijiayun from './baijiayun'

const initPlayer = function (data = {}, type = 1, time = 3000) {
  if (type === 2) {
    return new Baijiayun(data, time)
  } else {
    return new Polyv(data, time)
  }
}

export default initPlayer
