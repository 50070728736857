/**
 * SDK cdn 地址
 * 引入js 文件 version 字段参考下面版本号更新日志例如1.4.8
 * <script src="https://live-cdn.baijiayun.com/bplayer/{version}/bplayer.js"></script>
 * <script src="https://live-cdn.baijiayun.com/bplayer/{version}/dep/ffplayer.js"></script>
 * 播放器文档地址： https://dev.baijiayun.com/wiki/detail/228#-h0-1
 */

const throttle = function (func, delay) {
  let prev = Date.now()
  return function () {
    const context = this
    const args = arguments
    const now = Date.now()
    if (now - prev >= delay) {
      func.apply(context, args)
      prev = Date.now()
    }
  }
}

class Baijiayun {
  video = null
  event = {}
  constructor (param = {}, delay = 3000) {
    console.log(param)
    this.video = new window.BPlayer(param)
    this.timeupdate = throttle(() => {
      const timeupdate = this.event.timeupdate
      if (timeupdate && timeupdate.length) {
        const time = this.getCurrentTime()
        const duration = this.getDuration()
        timeupdate.forEach(fn => {
          fn && fn(time, duration)
        })
      }
    }, delay)
  }

  // 暂停播放
  pause () {
    this.video.pause()
  }

  // 播放视频 或者 恢复播放
  play () {
    this.video.play()
  }

  // 快进
  seek (s = 0) {
    this.video.seek(s)
  }

  // 获取当前播放时间点
  getCurrentTime () {
    return this.video.currentTime
  }

  // 获取总时长
  getDuration () {
    return this.video.duration
  }

  // 获取当前播放时间点 / 总时长的百分比
  getPercentage () {
    const time = this.getCurrentTime()
    const d = this.getDuration()
    console.log(time, d)
    if ((d - time) < 5) {
      return 100
    }
    return parseFloat((time / d * 100).toFixed(2))
  }

  // 获取播放器对象 用来实现没有实现统一api的调用
  getPlayer () {
    return this.video
  }

  // 销毁播放器
  destroy () {
    this.video.destroy()
  }

  // 这个只是统一监听事件名 事件回调的参数没有做统一，各平台不一样 需要自行判断好
  on (key = '', fn = () => {}) {
    // 进度更新流程 更新为多少秒才触发一次用来提交进度
    if (key === 'timeupdate') {
      const callbacks = this.event[key] || []
      callbacks.push(fn)
      this.event[key] = callbacks
      this.video.on('timeupdate', this.timeupdate)
      return this
    }

    // 百家云是首次播放和暂停后播放分开事件的 这里统一合成一个play事件
    if (key === 'play') {
      console.log(this.video);
      this.video.on('play', fn)
      this.video.on('playing', fn)
    }

    // 其他事件
    if (key) {
      this.video.on(key, fn)
    }
    return this
  }

  off (key = '', fn = () => {}) {
    // 进度更新流程 更新为多少秒才触发一次用来提交进度
    if (key === 'timeupdate') {
      const callbacks = this.event[key]
      this.event[key] = callbacks && callbacks.filter(callback => callback !== fn)
      return this
    }

    // 百家云是首次播放和暂停后播放分开事件的 这里统一合成一个play事件
    if (key === 'play') {
      this.video.off('play', fn)
      this.video.off('playing', fn)
    }
    // 其他事件
    if (key) {
      this.video.off(key, fn)
    }
    return this
  }
}

export default Baijiayun
